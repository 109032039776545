import React from "react";
import Seo from "../components/seo";
import { ErrorPage } from "../components/ErrorPage";
import { Layout } from "../components/Layout";

import { Helmet } from "react-helmet";

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="404 | Not found" />
      <Helmet>
        <meta name="icon" href="/static/favicon.ico" />
      </Helmet>
      <ErrorPage />
    </Layout>
  );
};

export default NotFoundPage;
